import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Link } from 'react-router-dom'

export function Wizard(props) {
  const [isOpen, toggleIsOpen] = useState(false)
  const wizardClass = classNames(
    {
      'c-wizard': true,
      'js-is-ready  ': true,
      'c-wizard--no-interaction  ': props.noInteraction,
    },
    props.className
  ).trim()

  const wizardListClass = classNames(
    {
      'c-wizard__list  ': true,
      'is-closed  ': !isOpen,
    },
    props.className
  ).trim()

  return (
    <nav
      className={wizardClass}
      data-wizard-collapse={props.collapseBreakpoint}
    >
      <button
        className="c-wizard__trigger"
        type="button"
        aria-expanded={isOpen}
        title={props.collapsedTitle}
        onClick={() => toggleIsOpen(!isOpen)}
      >
        <i className="c-wizard__trigger-icon" aria-hidden="true" />
        {props.collapsedText && (
          <span className="c-wizard__trigger-text">{props.collapsedText}</span>
        )}
        {props.collapsedCounter && (
          <span className="c-wizard__trigger-counter">
            {props.collapsedCounter}
          </span>
        )}
      </button>
      <ol className={wizardListClass}>{props.children}</ol>
    </nav>
  )
}

Wizard.Item = props => {
  const wizardItemClass = classNames(
    {
      'c-wizard__item  ': true,
      'is-done  ': props.isDone,
      'is-error  ': props.isError,
      'is-disabled  ': props.isDisabled,
      'is-active  ': props.isActive,
    },
    props.className
  ).trim()

  return (
    <li className={wizardItemClass}>
      <Link
        className="c-wizard__link"
        to={props.to ? props.to : ''}
        aria-disabled={props.isDisabled}
      >
        <span className="c-wizard__link-title">{props.children}</span>
      </Link>
    </li>
  )
}

Wizard.Item.displayName = 'Wizard.Item'

Wizard.propTypes = {
  className: PropTypes.string,
  children: props => {
    if (
      React.Children.toArray(props.children).some(
        child => child.type !== Wizard.Item
      )
    ) {
      return new Error("'Wizard' children should be of type 'Wizard.Item'")
    }
    return undefined
  },
  collapsedText: PropTypes.string,
  collapsedCounter: PropTypes.string,
  collapsedTitle: PropTypes.string,
  noInteraction: PropTypes.bool,
  collapseBreakpoint: PropTypes.string,
}

Wizard.Item.propTypes = {
  isDisabled: PropTypes.bool,
  isActive: PropTypes.bool,
  isError: PropTypes.bool,
  isDone: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  to: PropTypes.string,
}
