import React from 'react'
import PropTypes from 'prop-types'
import StickyPolyfill from 'react-stickyfill'

import { Heading } from '../../Atoms/Heading/Heading'

export const NotificationGroup = ({ children }) => {
  return (
    <StickyPolyfill>
      <section
        id="js-notifications-group"
        className="c-notifications-group  js-panelgroup  js-is-fx  js-is-ready"
      >
        {children}
      </section>
    </StickyPolyfill>
  )
}

export function Notification({
  children,
  testId,
  type = '',
  className = '',
  title,
  buttonText,
  statusIcon = false,
  onClick,
}) {
  const notificationTypes = Array.isArray(type)
    ? type.map(t => `c-notification--${t}`).join(' ')
    : `c-notification--${type}`

  return (
    <div
      data-testid={testId}
      className={`c-notification__container  js-notification-container   ${className}`}
    >
      <div className={`c-notification  ${notificationTypes}`}>
        <div className="c-notification__outer-wrap">
          <div className="c-notification__inner-wrap">
            {statusIcon && <i className="c-notification__status-icon"></i>}

            <div className="c-notification__content">
              {title && (
                <Heading headerType={3} className="c-notification__title">
                  {title}
                </Heading>
              )}
              {children && <p className="c-notification__text">{children}</p>}
            </div>

            {onClick && (
              /**
               * We're not using <Button> here because it always adds the c-btn
               * class which is not used in Bronson in case of the "X" icon.
               */
              <button
                type="button"
                className={`js-notification-close  ${
                  buttonText
                    ? 'c-btn  c-notification__button'
                    : 'c-notification__close'
                }`}
                onClick={onClick}
              >
                <span className="c-btn__text">{buttonText}</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Notification.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOfType([
    PropTypes.oneOf(['info', 'warning', 'success', 'error', 'custom']),
    PropTypes.array,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
}
