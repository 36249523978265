import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Paragraph } from '../Paragraph/Paragraph'

export function ErrorMessage(props) {
  const componentErrorMessageClass = classNames(
    {
      'c-error-message  ': true,
    },
    props.className
  ).trim()

  return (
    <Paragraph
      data-testid={props.testId}
      className={componentErrorMessageClass}
      id={props.id}
    >
      {props.children}
    </Paragraph>
  )
}

ErrorMessage.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
}

ErrorMessage.defaultProps = {
  className: '',
  id: '',
}
