/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Link as RouterLink } from 'react-router-dom'

export function Link({
  normalLink,
  testId,
  className,
  noStyling,
  icon,
  size,
  children,
  href,
  target,
  backToTop,
  iconClass,
  linkClass,
  onClick,
  ...otherProps
}) {
  const iconClassName = icon ? `c-icon--[${icon}]  ` : undefined

  const iconSizeClass = icon ? `c-icon--${size}  ` : undefined

  const componentLinkClass = classNames(
    {
      'c-link  ': !noStyling,
    },
    className
  ).trim()

  const componentLinkIconClass = classNames(
    {
      'c-icon  ': true,
      'c-link__icon  ': !!icon,
      [iconClassName]: true,
      [iconSizeClass]: size,
    },
    iconClass
  ).trim()

  const componentLinkLabelClass = classNames(
    {
      'c-link__effect  ': true,
    },
    linkClass
  ).trim()

  function renderLabel() {
    if (!icon) {
      return <span className={componentLinkLabelClass}>{children}</span>
    }
    return (
      <span className="c-link__body  ">
        <span className={componentLinkLabelClass}>{children}</span>
      </span>
    )
  }

  if (backToTop) {
    return (
      <div className="c-back-to-top  js-back-to-top">
        <div className="c-back-to-top__pagewrap">
          <button
            type="button"
            className="c-btn  c-btn--secondary  c-btn--icon  c-back-to-top__trigger  js-back-to-top-trigger"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            <i className="c-back-to-top__icon"></i>
            <span className="c-back-to-top__text">{children}</span>
          </button>
        </div>
      </div>
    )
  }

  if (normalLink) {
    return (
      <a
        data-testid={testId}
        href={href}
        className={componentLinkClass}
        target={target}
        onClick={onClick}
        {...otherProps}
      >
        <i className={componentLinkIconClass} />
        {renderLabel()}
      </a>
    )
  }

  return (
    <RouterLink
      data-testid={testId}
      to={href}
      className={componentLinkClass}
      target={target}
      {...otherProps}
    >
      <i className={componentLinkIconClass} />
      {renderLabel()}
    </RouterLink>
  )
}

Link.propTypes = {
  normalLink: PropTypes.bool,
  testId: PropTypes.string,
  className: PropTypes.string,
  noStyling: PropTypes.bool,
  icon: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
  target: PropTypes.string,
  backToTop: PropTypes.bool,
  iconClass: PropTypes.string,
  linkClass: PropTypes.string,
  onClick: PropTypes.func,
}
