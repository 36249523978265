import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function RangeSlider(props) {
  const rangeSliderWrapperClass = classNames(
    {
      'c-range-slider  ': true,
    },
    props.className
  ).trim()

  const rangeSliderClass = classNames({
    'c-range-slider__input': true,
  }).trim()

  return (
    <div className={rangeSliderWrapperClass}>
      <input
        data-testid={props.testId}
        name={props.name}
        className={rangeSliderClass}
        id={props.id}
        type="range"
        value={props.value}
        step={props.step}
        min={props.min}
        max={props.max}
        disabled={props.disabled ? 'disabled' : null}
        onChange={props.onChange}
        onBlur={props.onBlur}
        aria-labelledby={props.ariaLabelledby}
      />
    </div>
  )
}

RangeSlider.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.number,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  ariaLabelledby: PropTypes.string,
}
