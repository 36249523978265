import React from 'react'
import PropTypes from 'prop-types'

export function Image(props) {
  return <img data-testid={props.testId} src={props.src} alt={props.alt} />
}

Image.propTypes = {
  testId: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
}
