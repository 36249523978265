import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Link } from 'react-router-dom'

function getStepperNavItemStyle(props) {
  return classNames({
    'c-stepper-nav__item  ': true,
    'is-active  ': props.isActive,
    'is-current  ': props.isCurrent,
    'is-disabled  ': props.isDisabled,
  }).trim()
}

export function StepperNav(props) {
  const componentStepperNavClass = classNames(
    {
      'c-stepper-nav-wrap  ': true,
    },
    props.className
  ).trim()

  return (
    <nav className={componentStepperNavClass}>
      <ol className="c-stepper-nav  ">
        {React.Children.map(props.children, (child, index) => {
          return React.cloneElement(child, {
            ...child.props,
            className: getStepperNavItemStyle(child.props),
            key: index, // eslint-disable-line
          })
        })}
      </ol>
    </nav>
  )
}

StepperNav.Item = props => {
  return (
    <li className={props.className}>
      <Link
        className="c-stepper-nav__link"
        to={props.href}
        onClick={props.onClick}
      >
        {props.children}
      </Link>
    </li>
  )
}

StepperNav.Item.displayName = 'StepperNav.Item'

StepperNav.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(children => {
    if (
      React.Children.toArray(children).some(
        child => child.type !== StepperNav.Item
      )
    ) {
      return new Error(
        "'StepperNav' children should be of type 'StepperNav.Item'"
      )
    }
    return undefined
  }),
}

StepperNav.defaultProps = {
  className: '',
}

StepperNav.Item.propTypes = {
  isDisabled: PropTypes.bool, // eslint-disable-line
  isActive: PropTypes.bool, // eslint-disable-line
  isCurrent: PropTypes.bool, // eslint-disable-line
  className: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
}

StepperNav.Item.defaultProps = {
  isDisabled: false,
  isActive: false,
  isCurrent: false,
  href: '#0',
}
