import React from 'react'
import PropTypes from 'prop-types'

import { Notification, NotificationGroup } from '../Notification/Notification'

export function Cookie({
  children,
  testId,
  className = '',
  title,
  buttonText,
  onClick,
  type = 'custom',
}) {
  return (
    <NotificationGroup>
      <Notification
        title={title}
        type={type}
        testId={testId}
        className={className}
        buttonText={buttonText}
        onClick={onClick}
      >
        {children}
      </Notification>
    </NotificationGroup>
  )
}

Cookie.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOfType([
    PropTypes.oneOf(['info', 'warning', 'success', 'error', 'custom']),
    PropTypes.array,
  ]),
}
