import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Paragraph } from '../../Atoms/Paragraph/Paragraph'

export const HeroTeaser = ({
  imageUrl,
  imageSrcset,
  imageSizes,
  imageObjectFit = 'cover',
  title,
  subTitle,
  preTitle,
  text,
  className,
  imageAlt,
  focusPart,
  auto,
  inverted,
  shallow,
  buttonText,
  buttonOnClick,
}) => {
  const componentClass = classNames(
    {
      'c-hero-teaser  ': true,
      [`c-hero-teaser--${focusPart}  `]: focusPart,
      'c-hero-teaser--auto  ': auto,
      'c-hero-teaser--inverted  ': inverted,
      'c-hero-teaser--shallow  ': shallow,
    },
    className
  ).trim()

  useEffect(() => {
    // Workaround for React, need to be called after rerender
    if (window.objectFitPolyfill) {
      window.objectFitPolyfill()
    }
  })

  useEffect(() => {
    setTimeout(() => {
      if (window.objectFitPolyfill) {
        window.objectFitPolyfill()
      }
    }, 100)
  }, [])

  const imageObjectPosition = focusPart ? focusPart.replace('-', ' ') : 'center'

  return (
    <div className={componentClass}>
      <img
        src={imageUrl}
        srcSet={imageSrcset}
        className="c-hero-teaser__image"
        sizes={imageSizes}
        alt={imageAlt}
        data-object-fit={imageObjectFit}
        data-object-position={imageObjectPosition}
      />
      <div className="c-hero-teaser__content">
        <div className="c-hero-teaser__content-inner">
          <header className="c-hero-teaser__heading">
            {preTitle && (
              <div className="c-hero-teaser__pretitle">
                <Paragraph>{preTitle}</Paragraph>
              </div>
            )}
            <div className="c-hero-teaser__title">
              <h1>
                {[].concat(title).map((line, index) => {
                  return (
                    // eslint-disable-next-line
                    <span key={index} className="c-hero-teaser__title-row">
                      {line}
                    </span>
                  )
                })}
              </h1>
            </div>
            {subTitle && (
              <div className="c-hero-teaser__subtitle">
                <Paragraph>{subTitle}</Paragraph>
              </div>
            )}
            {text && (
              <div className="c-hero-teaser__text">
                <p>{text}</p>
              </div>
            )}
          </header>
          {buttonText && (
            <div className="c-hero-teaser__button">
              <button type="button" className="c-btn" onClick={buttonOnClick}>
                <span className="c-btn__text">{buttonText}</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

HeroTeaser.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageSrcset: PropTypes.string,
  imageSizes: PropTypes.string,
  imageObjectFit: PropTypes.string,
  title: PropTypes.node,
  subTitle: PropTypes.node,
  auto: PropTypes.bool,
  inverted: PropTypes.bool,
  shallow: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonOnClick: PropTypes.func,
  focusPart: PropTypes.string,
}
