/*
  eslint-disable jsx-a11y/no-static-element-interactions,
    jsx-a11y/no-noninteractive-element-interactions
*/
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '../../Atoms/Button/Button'
import { Heading } from '../../Atoms/Heading/Heading'
import { Paragraph } from '../../Atoms/Paragraph/Paragraph'

export function Modal(props) {
  const modalWrapper = useRef(null)

  const componentModalClass = classNames(
    {
      'c-modal  ': true,
      'c-modal--left  ': props.left,
      'c-modal--center  ': props.center,
      [`c-modal--${props.sizing}  `]: props.sizing,
      [`c-modal--${props.status}  `]: props.status,
    },
    props.className
  ).trim()

  function renderChildren() {
    if (typeof props.children === 'string') {
      return <Paragraph>{props.children}</Paragraph>
    }
    return props.children
  }

  function onClick(e) {
    if (modalWrapper.current !== e.currentTarget || props.closeAny) {
      props.onClose()
    }
    e.stopPropagation()
  }

  function onKeyDown(e) {
    if (e.keyCode === 27) {
      props.onClose()
    }
  }

  if (props.shown) {
    document.addEventListener('keydown', onKeyDown, false)
  } else {
    document.removeEventListener('keydown', onKeyDown, false)
  }

  return (
    <div
      data-testid={props.testId}
      className={componentModalClass}
      aria-hidden={!props.shown}
      id={props.id}
      onClick={props.closeAny ? onClick : props.onClickOutside}
    >
      <div className="c-modal__overlay  " tabIndex="-1" />
      <div
        className="c-modal__wrapper"
        role="dialog"
        aria-labelledby="modal-title-label"
        onClick={onClick}
        ref={modalWrapper}
      >
        <header className="c-modal__header">
          <div className="c-modal__title__wrapper">
            {props.status && <i className="c-modal__status-icon" />}
            <Heading
              headerType={1}
              className="c-modal__title"
              id="modal-title-label"
            >
              {props.title}
            </Heading>
          </div>
          {!props.hideCloseButton && (
            <button
              type="button"
              className="c-modal__close-btn  js-modal-close"
              onClick={onClick}
              aria-label={props.buttonCloseLabel}
            />
          )}
        </header>

        <div className="c-modal__content" role="document">
          {renderChildren()}
        </div>
        <div className="c-modal__footer">
          <div className="c-modal__actions">
            {props.buttonCancelText && (
              <div className="c-modal__actions__item">
                <Button
                  onClick={props.onCancel}
                  secondary
                  aria-label={props.buttonCancelLabel}
                >
                  <span className="c-btn__text">{props.buttonCancelText}</span>
                </Button>
              </div>
            )}
            <div className="c-modal__actions__item">
              {props.buttonConfirmText && (
                <Button
                  onClick={props.closeAny ? onClick : props.onConfirm}
                  aria-label={props.buttonConfirmLabel}
                  disabled={props.confirmationDisabled}
                >
                  {props.buttonConfirmText}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Modal.defaultProps = {
  hideCloseButton: false,
}

Modal.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  /**
   * For some reason, using just `PropTypes.node` makes storybook crash here.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  shown: PropTypes.bool,
  closeAny: PropTypes.bool,
  buttonConfirmText: PropTypes.string,
  buttonConfirmLabel: PropTypes.string,
  buttonCloseLabel: PropTypes.string,
  buttonCancelLabel: PropTypes.string,
  buttonCancelText: PropTypes.string,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onClickOutside: PropTypes.func,
  left: PropTypes.bool,
  center: PropTypes.bool,
  sizing: PropTypes.string,
  status: PropTypes.string,
  confirmationDisabled: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
}
