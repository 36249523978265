import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '../../Atoms/Button/Button'

export function ButtonContainer({
  testId,
  className,
  children,
  center,
  left,
  right,
  fixed,
  nav,
}) {
  const componentButtonContainerClass = classNames(
    {
      'o-button-container  ': true,
      'o-button-container--nav  ': nav,
      'o-button-container--fixed  ': fixed,
      'o-button-container--center  ': center,
      'o-button-container--left  ': left,
      'o-button-container--right  ': right,
    },
    className
  ).trim()

  const childrenWithExtraProp = React.Children.map(children, child => {
    return React.cloneElement(child, {
      className: 'o-button-container__button',
    })
  })

  return (
    <div data-testid={testId} className={componentButtonContainerClass}>
      {childrenWithExtraProp}
    </div>
  )
}

ButtonContainer.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  children: props => {
    if (
      React.Children.toArray(props.children).some(
        child => child.type !== Button
      )
    ) {
      return new Error("'ButtonContainer' children should be of type 'Button'")
    }
    return undefined
  },
}
