import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from '../../Atoms/Link/Link'
import { List } from '../../Atoms/List/List'

function Copyright(props) {
  if (props.copyright) {
    return (
      <span className="c-footer-copyright">
        <span className="c-footer-copyright__sign">{props.copyrightSign}</span>
        <span className="c-footer-copyright__text">{props.copyright}</span>
      </span>
    )
  }
  return null
}

export function Footer(props) {
  const componentFooterClass = classNames(
    {
      'c-footer  ': true,
    },
    props.className
  ).trim()

  const { orderedFootnotes } = props

  function renderElements() {
    return React.Children.map(props.children, (child, index) => {
      if (child.type === Footer.Item) {
        return (
          // eslint-disable-next-line
          <List.Item key={index}>
            <Link
              href={child.props.href}
              normalLink={child.props.target === '_blank'}
              target={child.props.target}
              noStyling
              className="c-footer-meta-nav__link"
              {...child.props}
            >
              {child}
            </Link>
          </List.Item>
        )
      }
      return null
    })
  }
  function renderFootnotes() {
    return React.Children.map(props.children, (child, index) => {
      if (child.type === Footer.Footnote) {
        return (
          // eslint-disable-next-line
          <List.Item bullet={child.props.bullet} key={index}>
            {child}
          </List.Item>
        )
      }
      return null
    })
  }

  const footNotes = renderFootnotes()

  return (
    <>
      {props.backToTopLabel && (
        <Link backToTop href="#top">
          {props.backToTopLabel}
        </Link>
      )}

      <footer className={componentFooterClass}>
        <div className="c-footer__module  c-footer__module--meta-nav">
          <div className="o-page-wrap">
            <nav className="c-footer-meta-bar">
              <List
                className="c-footer-meta-nav"
                listElemClassName="c-footer-meta-nav__item"
              >
                {renderElements()}
              </List>
              <Copyright
                copyright={props.copyright}
                copyrightSign={props.copyrightSign}
              />
            </nav>
          </div>
        </div>
        {footNotes.length !== 0 && (
          <div className="c-footer__module  c-footer__module--footnotes">
            <div className="o-page-wrap">
              <div className="c-footer-footnotes">
                <p>{props.footnotesTitle}</p>
                <List ordered={orderedFootnotes} className="c-footnotes">
                  {footNotes}
                </List>
              </div>
            </div>
          </div>
        )}
      </footer>
    </>
  )
}

Footer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  backToTopLabel: PropTypes.string,
  footnotesTitle: PropTypes.string,
  copyright: PropTypes.string,
  copyrightSign: PropTypes.string,
  orderedFootnotes: PropTypes.bool,
}

Footer.defaultProps = {
  copyrightSign: '©',
  orderedFootnotes: true,
}

Footer.Item = ({ children }) => {
  return children
}

Footer.Footnote = ({ children }) => {
  return children
}
