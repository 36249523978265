import React from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'

export function Tooltip({ content, children, placement, ...otherProps }) {
  function renderChildren() {
    if (typeof children === 'string') {
      return <span className="c-tooltip">{children}</span>
    }
    return children
  }
  return (
    <Tippy
      content={content}
      arrow
      hideOnClick={false}
      interactive
      placement={placement || 'right'}
      theme="default"
      animation="default"
      {...otherProps}
    >
      {renderChildren()}
    </Tippy>
  )
}

Tooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}
