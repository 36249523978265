import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

export const Logo = ({ href, alt, logoSrc, sLogoSrc }) => {
  const content = (
    <picture>
      <source srcSet={sLogoSrc} media="(max-width: 768px)" />
      <img src={logoSrc} alt={alt} className="c-logo__image" />
    </picture>
  )
  if (href) {
    return (
      <div className="c-vertical-bar__logo">
        <RouterLink className="c-logo  c-logo--has-image" to={href}>
          {content}
        </RouterLink>
      </div>
    )
  }
  return <div className="c-vertical-bar__logo">{content}</div>
}

Logo.propTypes = {
  href: PropTypes.string,
  alt: PropTypes.string,
  logoSrc: PropTypes.string,
  sLogoSrc: PropTypes.string,
}

const Button = ({ onClick, ariaHidden }) => (
  <button
    className="c-vertical-bar__toggle  js-vertical-bar__toggle"
    aria-expanded={!ariaHidden}
    onClick={onClick}
    type="button"
    title="Show navigation"
  >
    <i className="c-icon  c-icon--[menu]" aria-hidden={ariaHidden} />
  </button>
)

Button.propTypes = {
  ariaHidden: PropTypes.bool,
  onClick: PropTypes.func,
}

export const VerticalBar = ({ href, children, alt, logoSrc, sLogoSrc }) => {
  const [mobileMenuHidden, toggleMobileMenu] = useState(true)
  return (
    <nav className="c-vertical-bar  js-vertical-bar">
      <Logo href={href} alt={alt} logoSrc={logoSrc} sLogoSrc={sLogoSrc} />
      {children && (
        <Button
          onClick={() => toggleMobileMenu(!mobileMenuHidden)}
          ariaHidden={mobileMenuHidden}
        />
      )}
      {children && (
        <ul
          className="c-vertical-bar__nav  js-vertical-bar__nav"
          aria-hidden={mobileMenuHidden}
        >
          {React.Children.map(children, (child, index) => (
            // eslint-disable-next-line
            <li key={`${index}`} className="c-vertical-bar__nav-item">
              {child}
            </li>
          ))}
        </ul>
      )}
    </nav>
  )
}
VerticalBar.propTypes = {
  href: PropTypes.string,
  /**
   * For some reason, using just `PropTypes.node` makes storybook crash here.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  alt: PropTypes.string,
  logoSrc: PropTypes.string,
  sLogoSrc: PropTypes.string,
}

VerticalBar.defaultProps = {
  href: '#',
}

VerticalBar.Item = ({ icon, href, children }) => (
  <RouterLink className="c-vertical-bar__nav-link" to={href}>
    <i
      className={`c-vertical-bar__nav-link-icon  c-icon  c-icon--[${icon}]`}
      aria-hidden="true"
    />
    <span className="c-vertical-bar__nav-link-label">{children}</span>
  </RouterLink>
)

VerticalBar.Item.displayName = 'VerticalBar.Item'

VerticalBar.Item.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node,
}
