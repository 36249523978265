import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function Checkbox(props) {
  const componenCheckboxWrapperClass = classNames(
    {
      'c-checkbox  ': true,
    },
    props.className
  ).trim()

  const componenCheckboxInputClass = classNames({
    'c-checkbox__input  ': true,
    'is-error  ': props.isError,
  }).trim()

  const componenCheckboxLabelClass = classNames({
    'c-checkbox__label  ': true,
  }).trim()

  return (
    <label className={componenCheckboxWrapperClass}>
      <input
        data-testid={props.testId}
        className={componenCheckboxInputClass}
        type="checkbox"
        name={props.name}
        disabled={props.disabled ? 'disabled' : null}
        value={props.value}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
      <span className={componenCheckboxLabelClass}>
        {props.children}
        {props.notion && <span className="c-form-field__notion">*</span>}
      </span>
    </label>
  )
}

Checkbox.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  value: PropTypes.bool,
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  notion: PropTypes.bool,
}

Checkbox.defaultProps = {
  className: '',
  disabled: false,
  isError: false,
  checked: undefined,
  defaultChecked: undefined,
  value: false,
  name: '',
  notion: false,
}
