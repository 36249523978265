import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function Accordion(props) {
  const [shown, toggleShown] = useState(
    props.multipleOpen
      ? () => React.Children.map(props.children, () => false)
      : -1
  )

  const { toggleCb } = props

  useEffect(() => {
    if (typeof toggleCb === 'function') {
      toggleCb(shown)
    }
  }, [shown, toggleCb])

  useEffect(() => {
    const handleResize = () => {
      panelRefs.forEach((panel, index) => {
        if (panel.current.parentNode.ariaHidden === 'false') {
          panelRefs[
            index
          ].current.parentNode.style.height = `${panel.current.clientHeight}px`
        }
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.defaultOpen !== null || props.defaultOpen !== undefined) {
      if (Array.isArray(props.defaultOpen)) {
        toggleShown([...shown].map((_, i) => props.defaultOpen.indexOf(i) >= 0))
        return
      }
      toggleShown(props.defaultOpen)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const panelRefs = React.Children.map(props.children, () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useRef(null)
  })

  const componentAccordionClass = classNames(
    {
      'c-accordion  ': !props.footerAccordion,
      'c-footer-nav__column  ': props.footerAccordion,
      'js-footer-accordion  ': props.footerAccordion,
      'js-is-ready  ': props.animate,
      'c-accordion--bg-panel  ': props.withBackground,
      'js-is-fx  ': props.animate,
      'js-accordion  ': props.animate,
      'c-accordion--header-split  ': props.headerSplit,
    },
    props.className
  ).trim()

  function isActive(index) {
    return Array.isArray(shown) ? shown[index] : shown === index
  }

  function setShown(index) {
    if (Array.isArray(shown)) {
      toggleShown(
        shown.map((item, itemIndex) => {
          if (itemIndex === index) {
            return !item
          }
          return item
        })
      )
    } else {
      toggleShown(shown === index ? -1 : index)
    }
  }

  return (
    <div
      className={componentAccordionClass}
      data-tr-component="Accordion"
      data-tr-function={props.trFunction}
      data-tr-state={
        shown === -1 ||
        shown === undefined ||
        (Array.isArray(shown) && shown.filter(Boolean).length === 0)
          ? 'Collapsed'
          : 'Expanded'
      }
    >
      {React.Children.map(props.children, (child, index) => {
        const componentAccordionItemClass = classNames({
          'c-accordion__header  ': !props.footerAccordion,
          'c-footer-nav__title  ': props.footerAccordion,
          'js-accordion__title': props.animate,
          'is-active  ': isActive(index),
        }).trim()

        const panelStyle = {
          height: isActive(index)
            ? `${panelRefs[index].current.clientHeight}px`
            : '0px',
          visibility: isActive(index) ? 'visible' : 'hidden',
          overflowY: 'hidden',
        }
        return (
          // eslint-disable-next-line
          <React.Fragment key={index}>
            {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
            <div
              className={componentAccordionItemClass}
              onClick={() => {
                setShown(index)
              }}
              role="button"
            >
              {!props.footerAccordion && (
                <span className="c-accordion__title-icon" />
              )}
              {!props.footerAccordion && (
                <h3 className="c-accordion__title">
                  <button
                    className={`c-accordion__title-label  ${
                      props.animate ? 'js-accordion__title-label' : ''
                    }`}
                    aria-expanded={isActive(index)}
                    type="button"
                  >
                    <span className="c-accordion__title-label-text">
                      {child.props.title}
                    </span>
                    {child.props.title2 && (
                      <span className="c-accordion__title-label-text">
                        {child.props.title2}
                      </span>
                    )}
                  </button>
                </h3>
              )}
              {props.footerAccordion && (
                <React.Fragment>
                  <h4 className="c-footer-nav__title-label">
                    {child.props.title}
                  </h4>
                  {/* eslint-disable-next-line max-len */}
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button
                    className="c-footer-nav__title-label"
                    aria-expanded={isActive(index)}
                    type="button"
                  />
                </React.Fragment>
              )}
              {child.props.subtitle && (
                <p className="c-accordion__subtitle">{child.props.subtitle}</p>
              )}
            </div>
            {!props.footerAccordion && (
              <div
                style={panelStyle}
                className={`c-accordion__panel  ${
                  props.animate
                    ? 'js-is-fx  js-accordion__panel  js-is-ready  '
                    : ''
                }`}
                aria-hidden={!isActive(index)}
              >
                <div ref={panelRefs[index]} className="c-accordion__content">
                  {child}
                </div>
              </div>
            )}
            {props.footerAccordion && (
              <div style={panelStyle} aria-hidden={!isActive(index)}>
                <div ref={panelRefs[index]}>{child}</div>
              </div>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

Accordion.Item = ({ children }) => {
  return children
}

Accordion.Item.displayName = 'Accordion.Item'

Accordion.Item.propTypes = {
  title: PropTypes.node,
  title2: PropTypes.node,
  children: PropTypes.node,
}

Accordion.propTypes = {
  footerAccordion: PropTypes.bool,
  className: PropTypes.string,
  withBackground: PropTypes.bool,
  multipleOpen: PropTypes.bool,
  animate: PropTypes.bool,
  trFunction: PropTypes.string,
  headerSplit: PropTypes.bool,
  children: props => {
    if (
      React.Children.toArray(props.children).some(
        child => child.type !== Accordion.Item
      )
    ) {
      return new Error(
        "'Accordion' children should be of type 'Accordion.Item'"
      )
    }
    return undefined
  },
  defaultOpen: props => {
    if (!props.defaultOpen && props.defaultOpen !== 0) {
      return undefined
    }

    if (
      !Array.isArray(props.defaultOpen) &&
      !Number.isInteger(props.defaultOpen)
    ) {
      return new Error(
        'defaultOpen prop should be an integer or an array of integers'
      )
    }

    if (Array.isArray(props.defaultOpen) && !props.multipleOpen) {
      return new Error(
        'defaultOpen prop should be an integer when multipleOpen is false'
      )
    }

    if (Number.isInteger(props.defaultOpen) && props.multipleOpen) {
      return new Error(
        'defaultOpen prop should be an array of integers when multipleOpen is true'
      )
    }

    return undefined
  },
  toggleCb: PropTypes.func,
}

Accordion.defaultProps = {
  footerAccordion: false,
  className: '',
  withBackground: false,
  multipleOpen: false,
  animate: false,
  trFunction: '',
  headerSplit: false,
}
