import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Logo } from '../VerticalBar/VerticalBar'

// WIP: The functionality of Header component is currently very limited,
//      it handles logo and title display only.
export function Header({
  title,
  className,
  href,
  alt,
  logoSrc,
  sLogoSrc,
  staticHeader,
}) {
  const componentHeaderClass = classNames(
    {
      'c-header__wrapper  ': true,
    },
    className
  ).trim()

  const divHeaderClassname = classNames(
    {
      'c-header  ': true,
      'c-header--static  ': staticHeader,
    },
    className
  )

  return (
    <header className={componentHeaderClass}>
      <div className={divHeaderClassname}>
        <div className="c-header__content-wrapper">
          <div className="c-header__logo">
            <Logo href={href} alt={alt} logoSrc={logoSrc} sLogoSrc={sLogoSrc} />
          </div>

          {title && (
            <div className="c-header__nav">
              <div className="c-context-bar">
                <div className="c-context-bar__panel" aria-hidden="true">
                  <nav className="c-context-bar__navs" aria-label="primary">
                    <ul className="c-context-bar__nav">
                      <li>
                        <span className="c-context-bar__title">{title}</span>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.string,
  alt: PropTypes.string,
  logoSrc: PropTypes.string,
  sLogoSrc: PropTypes.string,
}
