import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function RadioButton(props) {
  const radioButtonWrapperClass = classNames(
    {
      'c-radio  ': true,
    },
    props.className
  ).trim()

  const radioButtonClass = classNames({
    'c-radio__input  ': true,
    'is-error': props.isError,
  }).trim()

  const radioButtonLabelClass = classNames({
    'c-radio__label  ': true,
  }).trim()

  return (
    <label className={radioButtonWrapperClass}>
      <input
        data-testid={props.testId}
        className={radioButtonClass}
        type="radio"
        name={props.name}
        disabled={props.disabled ? 'disbled' : null}
        value={props.value}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        onChange={props.onChange}
      />
      <span className={radioButtonLabelClass}>{props.children}</span>
    </label>
  )
}

RadioButton.propTypes = {
  testId: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isError: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
}

function RadioButtonGroup({ children }) {
  return (
    <div className="o-inline-group">
      {React.Children.map(children, child => (
        <span className="o-inline-group__item">{child}</span>
      ))}
    </div>
  )
}

RadioButtonGroup.propTypes = {
  children: props => {
    if (
      React.Children.toArray(props.children).some(
        child => child.type !== RadioButton
      )
    ) {
      return new Error(
        "'RadioButton.Group' children should be of type 'RadioButton'"
      )
    }
    return undefined
  },
}

RadioButton.Group = RadioButtonGroup
