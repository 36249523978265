import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function Input(props) {
  const componentInputWrapperClass = classNames(
    {
      'c-input  ': true,
      'c-input--ghost  ': props.ghost,
      'c-input--reversed  ': props.addOnReversed,
      'c-input--textarea  ': props.textarea,
    },
    props.className
  ).trim()

  const componentInputClass = classNames({
    'c-input__input  ': true,
    'is-error  ': props.isError,
  }).trim()

  const componentInputAddOnClass = classNames({
    'c-input__addon  ': true,
    'c-input__addon--no-background  ': !props.addOnBackground,
  }).trim()

  function inputName() {
    return props.name
  }

  function inputId() {
    return props.id
  }

  function inputType() {
    return props.type || 'text'
  }

  function inputValue() {
    return props.value
  }

  function inputPlaceholder() {
    return props.placeholder
  }

  function renderInput() {
    if (!props.textarea) {
      return (
        <input
          data-testid={props.testId}
          className={componentInputClass}
          type={inputType()}
          id={inputId()}
          value={inputValue()}
          placeholder={inputPlaceholder()}
          name={inputName()}
          disabled={props.disabled ? 'disabled' : null}
          readOnly={props.readOnly ? 'readonly' : null}
          pattern={props.pattern ? props.pattern : null}
          title={props.title ? props.title : null}
          onChange={props.onChange}
          onBlur={props.onBlur}
          aria-labelledby={props.ariaLabelledby}
          data-tr-component="Input Field"
          data-tr-function={props.trFunction}
        />
      )
    }
    return (
      <textarea
        data-testid={props.testId}
        className={componentInputClass}
        id={inputId()}
        name={inputName()}
        placeholder={inputPlaceholder()}
        cols={props.cols}
        rows={props.rows}
        disabled={props.disabled ? 'disabled' : null}
        readOnly={props.readOnly ? 'readonly' : null}
        onChange={props.onChange}
        onBlur={props.onBlur}
        aria-labelledby={props.ariaLabelledby}
        data-tr-function={props.trFunction}
      >
        {props.value}
      </textarea>
    )
  }

  function renderIcon() {
    const iconClass = `c-icon--[${props.addOn}]  `
    const className = `c-icon  ${iconClass}`
    return <i className={className} aria-hidden="true" />
  }

  function renderButton() {
    return (
      <button
        type="button"
        className={componentInputAddOnClass}
        onClick={props.onAddOnButtonClick}
      >
        {props.addOnIcon ? renderIcon() : props.addOn}
      </button>
    )
  }

  function renderAddOn() {
    if (!props.addOn) {
      return null
    }

    if (!props.addOnButton) {
      return (
        <span className={componentInputAddOnClass}>
          {props.addOnIcon ? renderIcon() : props.addOn}
        </span>
      )
    }
    return renderButton()
  }

  return (
    <div className={componentInputWrapperClass}>
      {renderInput()}
      {renderAddOn()}
    </div>
  )
}

Input.propTypes = {
  testId: PropTypes.string, // eslint-disable-line
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool, // eslint-disable-line
  disabled: PropTypes.bool, // eslint-disable-line
  pattern: PropTypes.string, // eslint-disable-line
  title: PropTypes.string, // eslint-disable-line
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  isError: PropTypes.bool,
  addOn: PropTypes.string,
  addOnIcon: PropTypes.bool,
  addOnButton: PropTypes.bool,
  addOnBackground: PropTypes.bool,
  addOnReversed: PropTypes.bool,
  ghost: PropTypes.bool,
  textarea: PropTypes.bool,
  cols: PropTypes.number, // eslint-disable-line
  rows: PropTypes.number, // eslint-disable-line
  onChange: PropTypes.func, // eslint-disable-line
  onBlur: PropTypes.func, // eslint-disable-line
  onAddOnButtonClick: PropTypes.func, // eslint-disable-line
  ariaLabelledby: PropTypes.string, // eslint-disable-line
  trFunction: PropTypes.string, // eslint-disable-line
}
