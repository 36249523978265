/* eslint-disable react/no-unused-prop-types */

import React, { useState } from 'react'
import Flatpickr from 'react-flatpickr'
import pl from 'flatpickr/dist/l10n/pl'
import it from 'flatpickr/dist/l10n/it'
import de from 'flatpickr/dist/l10n/de'
import cs from 'flatpickr/dist/l10n/cs'
import es from 'flatpickr/dist/l10n/es'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import TextMaskInput from 'react-text-mask'

const disabledButtonStyle = { pointerEvents: 'none' }

export function DatePicker(props) {
  const [date, setDate] = useState(props.value ? props.value : '')

  const componentDatePickerWrapperClass = classNames(
    {
      'c-input  ': true,
      'c-input--date  ': true,
    },
    props.className
  ).trim()

  const componentDatePickerClass = classNames(
    {
      'c-input__input  ': true,
      'is-error  ': props.isError,
    },
    props.datePickerclassName
  ).trim()

  const componentDatePickerButtonClass = classNames({
    'c-input__addon  ': true,
    'c-input__addon--no-background  ': !props.addOnBackground,
  }).trim()

  const componentDatePickerButtonIconClass = classNames({
    'c-icon  ': true,
    'c-icon--[semantic-calendar]  ': true,
  }).trim()

  function prepareMask() {
    const separator = props.dateFormat.substring(1, 2)
    const tokens = props.dateFormat.split(separator).map(item => {
      return item === item.toUpperCase()
        ? [/\d/, /\d/, /\d/, /\d/]
        : [/\d/, /\d/]
    })

    return [].concat(tokens[0], separator, tokens[1], separator, tokens[2])
  }
  function preparePlaceholder() {
    const separator = props.dateFormat.substring(1, 2)
    const tokens = props.dateFormat.split(separator).map(item => {
      return item === item.toUpperCase()
        ? `${item.toLowerCase()}${item.toLowerCase()}${item.toLowerCase()}${item.toLowerCase()}`
        : `${item}${item}`
    })
    return tokens.join(separator)
  }

  function setLocale() {
    switch (props.locale) {
      case 'pl':
        return pl.pl
      case 'de':
        return de.de
      case 'it':
        return it.it
      case 'cs':
        return cs.cs
      case 'es':
        return es.es
      default:
        return null
    }
  }

  function onChange(e) {
    const value = e.target === undefined ? e : e.target.value
    if (
      (!props.guide && value.length === preparePlaceholder().length) ||
      (!props.guide && value.length === 0) ||
      (!!props.guide && value.indexOf('_') === -1)
    ) {
      setDate(value)
      props.onChange(value)
    }
  }

  function renderInput() {
    return (
      <TextMaskInput
        data-testid={props.testId}
        className={componentDatePickerClass}
        type="text"
        id={props.id}
        value={date}
        name={props.name}
        onChange={onChange}
        onBlur={props.onBlur}
        disabled={props.disabled ? 'disabled' : null}
        readOnly={props.readOnly ? 'readonly' : null}
        aria-labelledby={props.ariaLabelledby}
        data-input
        mask={prepareMask()}
        showMask={!!props.showMask}
        guide={!!props.guide}
        placeholder={props.placeholder || preparePlaceholder()}
      />
    )
  }

  function renderIcon() {
    return (
      <i className={componentDatePickerButtonIconClass} aria-hidden="true" />
    )
  }

  function renderButton() {
    return (
      <button
        className={componentDatePickerButtonClass}
        type="button"
        data-toggle
        style={
          props.readOnly || props.disabled ? disabledButtonStyle : undefined
        }
      >
        {renderIcon()}
      </button>
    )
  }

  return (
    <div className={componentDatePickerWrapperClass}>
      <Flatpickr
        value={date}
        onChange={(_, value) => onChange(value)}
        options={{
          wrap: true,
          monthSelectorType: props.monthSelectorType,
          allowInput: !props.readOnly,
          mode: props.mode,
          locale: setLocale(),
          dateFormat: props.dateFormat,
          disableMobile: true,
          maxDate: props.maxDate,
          minDate: props.minDate,
          prevArrow: props.prevArrow,
          nextArrow: props.nextArrow,
        }}
        style={{ width: '100%', display: 'flex' }}
      >
        {renderInput()}
        {renderButton()}
      </Flatpickr>
    </div>
  )
}

DatePicker.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  datePickerclassName: PropTypes.string,
  isError: PropTypes.bool,
  addOnBackground: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  monthSelectorType: PropTypes.string,
  prevArrow: PropTypes.string,
  nextArrow: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  mode: PropTypes.string,
  ariaLabelledby: PropTypes.string,
  dateFormat: PropTypes.string,
  showMask: PropTypes.bool,
  guide: PropTypes.bool,
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
}

DatePicker.defaultProps = {
  className: '',
  datePickerclassName: '',
  isError: false,
  addOnBackground: false,
  disabled: false,
  readOnly: false,
  mode: 'single',
  dateFormat: 'Y-m-d',
  monthSelectorType: 'static',
  prevArrow: '<i class="c-icon c-icon--[semantic-back] c-icon-–simple"></i>',
  nextArrow: '<i class="c-icon c-icon--[semantic-forward] c-icon-–simple"></i>',
}
