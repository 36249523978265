import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export function Spinner(props) {
  const componentSpinnerClass = classNames(
    {
      'c-spinner': true,
      'c-spinner--center': props.center,
      'c-spinner--small': props.small,
      'c-spinner--section': props.section,
      'c-spinner--full-page': props.fullPage,
      'c-spinner--text': props.text,
    },
    props.className
  ).trim()

  return (
    <div
      data-testid={props.testId}
      className={componentSpinnerClass}
      aria-live="polite"
      aria-busy={props.visible}
    >
      {props.text ? (
        <div className="c-spinner__content">
          {props.children}
          <div className="c-spinner__loader">
            <span className="c-spinner__element" />
            <span className="c-spinner__element" />
            <span className="c-spinner__element" />
            <span className="c-spinner__element" />
            <span className="c-spinner__element" />
            <span className="c-spinner__element" />
            <span className="c-spinner__element" />
            <span className="c-spinner__element" />
          </div>
          <div className="c-spinner__text">
            <p>{props.text}</p>
          </div>
        </div>
      ) : (
        <>
          {props.children}
          <div className="c-spinner__loader">
            <span className="c-spinner__element" />
            <span className="c-spinner__element" />
            <span className="c-spinner__element" />
            <span className="c-spinner__element" />
            <span className="c-spinner__element" />
            <span className="c-spinner__element" />
            <span className="c-spinner__element" />
            <span className="c-spinner__element" />
          </div>
        </>
      )}
    </div>
  )
}

Spinner.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  center: PropTypes.bool,
  small: PropTypes.bool,
  section: PropTypes.bool,
  fullPage: PropTypes.bool,
  visible: PropTypes.bool,
  children: PropTypes.node,
  text: PropTypes.string,
}

Spinner.defaultProps = {
  testId: '',
  className: '',
  center: false,
  small: true,
  section: false,
  fullPage: false,
  visible: true,
  text: '',
}
