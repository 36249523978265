import React from 'react'
import PropTypes from 'prop-types'

export function Paragraph({ children, testId, ...otherProps }) {
  return (
    <p data-testid={testId} {...otherProps}>
      {children}
    </p>
  )
}

Paragraph.propTypes = {
  testId: PropTypes.string,
  children: PropTypes.node.isRequired,
}
